// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-center-tsx": () => import("./../../../src/pages/center.tsx" /* webpackChunkName: "component---src-pages-center-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-core-competency-tsx": () => import("./../../../src/pages/core-competency.tsx" /* webpackChunkName: "component---src-pages-core-competency-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-pages-wonjin-tsx": () => import("./../../../src/pages/wonjin.tsx" /* webpackChunkName: "component---src-pages-wonjin-tsx" */)
}

